import { useCallback } from 'react';
import { useStorage } from 'bb/storage';

export type UseStoredPartnerUserId = string;

/**
 * Stores the current partner (from a partner signup flow) in localStorage per userId.
 * It stores for multiple users in the same object so multiple user can signup on the same device.
 */
export const useStoredPartner = (userId?: UseStoredPartnerUserId) => {
    const { value: storageValue, setValue: setStorageValue } = useStorage(
        'localStorage',
        'bb_partner'
    );

    const setPartner = useCallback(
        (userIdKey: UseStoredPartnerUserId, partner: string) => {
            setStorageValue({
                ...storageValue,
                [userIdKey]: partner.toLowerCase()
            });
        },
        [storageValue, setStorageValue]
    );

    const partner = userId ? storageValue?.[userId] : undefined;

    return { partner, setPartner };
};
