import { canUseLocalStorage } from 'bb/utils';
import { canUseSessionStorage } from 'bb/utils/environment';
import type { LocalStorageItems, SessionStorageItems } from './types';

export type StorageType = 'localStorage' | 'sessionStorage';

export const getStorageItem = <
    TStorageType extends StorageType,
    TStorage extends
        | LocalStorageItems
        | SessionStorageItems = TStorageType extends 'localStorage'
        ? LocalStorageItems
        : SessionStorageItems,
    TStorageKey extends keyof TStorage = keyof TStorage
>(
    storageType: TStorageType,
    key: Extract<TStorageKey, string>
): TStorage[TStorageKey] | null => {
    if (
        storageType === 'localStorage'
            ? canUseLocalStorage()
            : canUseSessionStorage()
    ) {
        try {
            return JSON.parse(window[storageType].getItem(key) ?? 'null');
        } catch {
            return null;
        }
    }

    return null;
};

export const setStorageItem =
    <
        TStorageType extends StorageType,
        TStorage extends
            | LocalStorageItems
            | SessionStorageItems = TStorageType extends 'localStorage'
            ? LocalStorageItems
            : SessionStorageItems,
        TStorageKey extends keyof TStorage = keyof TStorage
    >(
        storageType: TStorageType,
        key: Extract<TStorageKey, string>
    ) =>
    (value: TStorage[TStorageKey]) => {
        if (
            storageType === 'localStorage'
                ? canUseLocalStorage()
                : canUseSessionStorage()
        ) {
            try {
                const val = JSON.stringify(value);
                window[storageType].setItem(key, val);

                return val;
            } catch {
                return null;
            }
        }

        return null;
    };

export const removeStorageItem = <
    TStorageType extends StorageType,
    TStorage extends
        | LocalStorageItems
        | SessionStorageItems = TStorageType extends 'localStorage'
        ? LocalStorageItems
        : SessionStorageItems,
    TStorageKey extends keyof TStorage = keyof TStorage
>(
    storageType: TStorageType,
    key: Extract<TStorageKey, string>
) => {
    if (
        storageType === 'localStorage'
            ? canUseLocalStorage()
            : canUseSessionStorage()
    ) {
        window[storageType].removeItem(key);
    }
};
