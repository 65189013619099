import { type Routes } from 'bb/app/router/config';

export const getAccountViewRoute = (
    routes: Routes,
    key: string,
    activatePartnerAccount = false
) => {
    if (key === 'activateaccount') {
        return activatePartnerAccount
            ? routes.partnerSubscription
            : routes.registration;
    }
    return routes[key as keyof Routes] || routes['myaccount'];
};
