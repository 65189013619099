import React from 'react';
import dynamic from 'next/dynamic';
import css from './breadcrumbs.module.scss';

const FallbackBreadcrumbs = () => <div className={css.root} />;

export const LazyBreadcrumbs = dynamic(
    () => import('./Breadcrumbs').then((m) => m.Breadcrumbs),
    { ssr: false, loading: FallbackBreadcrumbs }
);

export * from './Breadcrumbs';
export * from './Breadcrumbs.types';
export * from './helpers';
export * from './NavBreadcrumbs';
