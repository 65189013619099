import { useEffect } from 'react';
import { getHalLinkHref, getHalLinkMethod } from 'bb/common/hal';
import type { HalLink, AnyRecord } from 'bb/common/types';
import { type MutationMethods } from '../types';
import {
    useHalLiveQueries,
    type HalLiveQueriesRevalidateKey
} from './halLiveQueries';
import { useApi } from './useApi';
import { type UseApiOptions } from './useApi/useApi.types';
import { type UseMutationOptions, useMutation } from './useMutation';

export type UseHalGetOptions<Response> = {
    /**
     * If a key is provided the corresponding href
     * will be stored in the halLiveQueries. We can
     * then use the same key to refresh the data.
     */
    revalidateKey?: HalLiveQueriesRevalidateKey;
} & UseApiOptions<Response>;

export const useHALGet = <Response = AnyRecord>(
    link?: HalLink | null,
    {
        revalidateKey,
        addAsLiveQuery = !revalidateKey,
        ...config
    }: UseHalGetOptions<Response> = {}
) => {
    const halLiveQueries = useHalLiveQueries();
    const href = getHalLinkHref(link);

    useEffect(() => {
        if (revalidateKey && href) {
            halLiveQueries.state.set(revalidateKey, href);
        }
    }, [halLiveQueries, revalidateKey, href]);

    return useApi<Response, 'generic'>(href, {
        ...config,
        addAsLiveQuery
    });
};

export const useHALMutation = <Payload = AnyRecord, Response = AnyRecord>(
    link?: HalLink,
    config?: UseMutationOptions<Payload, Response>
) =>
    useMutation<Payload, Response, Record<string, unknown>, 'generic'>(
        getHalLinkMethod(link) as MutationMethods,
        getHalLinkHref(link) as string,
        config
    );
