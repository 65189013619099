import React from 'react';
import classnames from 'classnames';
import css from './sideMenu.module.scss';

export type SideMenuListProps = {
    /**
     * Whether the list is nested within another list.
     *
     * If `true` the element will have a padding.
     *
     * @default false
     */
    isNested?: boolean;
} & React.ComponentProps<'ul'>;

export const SideMenuList = ({
    children,
    className,
    isNested = false,
    ...restProps
}: SideMenuListProps) => (
    <ul
        {...restProps}
        className={classnames(
            css.sideMenuList,
            isNested && css.sideMenuListIsNested,
            className
        )}
    >
        {children}
    </ul>
);
