import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { useAccountViews } from 'bb/account/hooks/useAccountViews';
import { useUser } from 'bb/account/hooks/useUser';
import { useIsLoggedIn } from 'bb/account/hooks/useVerifyLoggedIn';
import { getAccountViewRoute } from 'bb/account/utils';
import { type Routes, useIsNavigating, useRouter } from 'bb/app/router';
import { useMarket } from 'bb/app/useMarket';
import { getMarketConfig } from 'bb/config/marketConfig';
import { useTranslation, Text } from 'bb/i18n';
import { useStoredPartner } from 'bb/registration/link/hooks/useStoredPartner';
import { Box, MenuIcon, Gap, Drawer, DrawerHeader } from 'bb/ui';
import { TextButton } from 'bb/ui/Button';
import { MenuActions } from '../MenuActions';
import css from '../navbar.module.scss';
import { type MenuProps } from '../types';
import { SideMenuItem, type SideMenuItemProps } from './SideMenuItem';
import { SideMenuList } from './SideMenuList';

export const SideMenu = ({ showGiftCard, showStudent }: MenuProps) => {
    const { views: accountViews } = useAccountViews();
    const [isSideMenuShowing, setIsSideMenuShowing] = useState(false);
    const isNavigating = useIsNavigating();
    const { t } = useTranslation(['nav']);
    const { routes } = useRouter();
    const isLoggedIn = useIsLoggedIn();
    const { market } = useMarket();
    const inverted = isLoggedIn ? 'primary-white' : 'primary-black';

    const { showPartners } = getMarketConfig(market) || {};

    const { user } = useUser();
    const { partner } = useStoredPartner(user?.userid.toString());

    const closeSideMenu = useCallback(() => setIsSideMenuShowing(false), []);

    useEffect(() => {
        if (isNavigating) {
            closeSideMenu();
        }
    }, [closeSideMenu, isNavigating]);

    const makeRoute = (key: keyof Routes) => ({
        linkProps: { route: routes[key] },
        label: t(`nav:${key}`)
    });

    const rootKey = accountViews?.[0]?.key;
    /**
     * Delete when backend updates the key.
     */
    const rootRoute = (
        rootKey === 'subscription' ? 'myaccount' : rootKey
    ) as keyof Routes;

    const menuItems: (SideMenuItemProps | undefined)[] = [
        isLoggedIn
            ? {
                  ...makeRoute(rootRoute),
                  children: accountViews?.slice(1).map((view) => ({
                      label: t(`nav:${view.key}`),
                      linkProps: {
                          route: getAccountViewRoute(
                              routes,
                              view.key,
                              Boolean(partner)
                          ),
                          query:
                              view.key === 'activateaccount' && partner
                                  ? {
                                        partner
                                    }
                                  : undefined
                      }
                  }))
              }
            : undefined,
        makeRoute('books'),
        makeRoute('categories'),
        makeRoute('search'),
        showGiftCard
            ? /**
               * Casing is not consistent for this specific page.
               */
              { ...makeRoute('buyGiftcards'), label: t('nav:buyGiftCards') }
            : undefined,
        showStudent ? makeRoute('student') : undefined,
        showPartners && isLoggedIn ? makeRoute('partners') : undefined
    ];

    return (
        <div>
            <div
                className={classNames(
                    css.openMenuButtonContainer,
                    isLoggedIn && css.show
                )}
                aria-hidden={isSideMenuShowing}
                data-testid="mobile-menu-button"
            >
                <TextButton
                    onClick={() => setIsSideMenuShowing(true)}
                    disableAnimation
                    aria-haspopup="menu"
                    aria-label={
                        isSideMenuShowing
                            ? t('nav:closeMenu')
                            : t('nav:openMenu')
                    }
                    color={inverted}
                >
                    <Gap direction="column" alignItems="center">
                        <MenuIcon size="medium" color={inverted} />
                        <Text
                            as="span"
                            variant="body3"
                            t="nav:accessibility_menu"
                        />
                    </Gap>
                </TextButton>
            </div>

            {/* when not logged in for desktop, show actions */}
            {!isLoggedIn && (
                <div
                    className={classNames(
                        css.topActions,
                        !isLoggedIn && css.show
                    )}
                >
                    <MenuActions />
                </div>
            )}

            <Drawer
                direction="right"
                directionOverrides={{ right: { width: '300px' } }}
                isOpen={isSideMenuShowing}
                onAfterClose={closeSideMenu}
                shouldCoverFullScreenInResponsive
            >
                {({ handleClose }) => (
                    <>
                        <DrawerHeader padding={4} handleClose={handleClose} />
                        <Box padding={[0, 4, 4, 4]}>
                            <Gap spacing={6}>
                                <SideMenuList>
                                    {menuItems.map((item) =>
                                        item ? (
                                            <SideMenuItem
                                                key={item.label}
                                                {...item}
                                            />
                                        ) : null
                                    )}
                                </SideMenuList>

                                <Gap spacing={3}>
                                    <MenuActions fluid />
                                </Gap>
                            </Gap>
                        </Box>
                    </>
                )}
            </Drawer>
        </div>
    );
};
