export type FocusTrapOptions = {
    /**
     * Elements that should be excluded from the inert state.
     *
     * Can be either a query selector string or a HTMLElement.
     */
    excludedElements?: (string | HTMLElement)[];
};

export const FOCUSED_ELEMENT_DATA_ATTRIBUTE = 'data-focused-element';
export const INERT_ELEMENT_DATA_ATTRIBUTE = 'data-inert-element';
export const IFRAME_OVERLAY_CLASSNAME = 'iframe-overlay';

export class FocusTrap<TElement extends HTMLElement = HTMLElement> {
    #id = crypto.randomUUID();
    #element: TElement;
    #options: FocusTrapOptions;

    constructor(target: TElement, options: FocusTrapOptions = {}) {
        this.#element = target;
        this.#options = options;
    }

    #getExcludedElements() {
        return (this.#options.excludedElements ?? []).map((element) => {
            if (typeof element === 'string') {
                return document.querySelector(element);
            }

            return element;
        });
    }

    #getInertElements() {
        return Array.from(
            document.querySelectorAll(
                `[${INERT_ELEMENT_DATA_ATTRIBUTE}="${this.#id}"]`
            )
        ) as HTMLElement[];
    }

    /**
     * When the parent of an iframe is made inert the iframe can potentially
     * still be interacted with. This is a workaround to make sure that an
     * iframe is still not interactable when its parent is made inert.
     */
    #makeIframeNonInteractive(iframe: HTMLIFrameElement) {
        const parent = iframe.parentElement;
        if (
            parent &&
            ['relative', 'absolute'].indexOf(
                getComputedStyle(parent).position
            ) === -1
        ) {
            parent.style.position = 'relative';
        }

        const overlay = document.createElement('div');
        overlay.className = IFRAME_OVERLAY_CLASSNAME;
        overlay.style.position = 'absolute';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        iframe.parentElement?.appendChild(overlay);
        iframe.setAttribute('aria-hidden', 'true');
    }

    #makeIframeInteractive(iframe: HTMLIFrameElement) {
        const parent = iframe.parentElement;
        if (parent) {
            const overlay = parent.querySelector(
                `.${IFRAME_OVERLAY_CLASSNAME}`
            );
            overlay?.remove();
        }
        iframe.removeAttribute('aria-hidden');
    }

    #makeOutsideElementsInert() {
        let currentElement: HTMLElement | null = this.#element;

        const excludedElements = this.#getExcludedElements();

        /**
         * Iterate through the parent elements of the current element
         * and make all siblings inert. This will make sure that only
         * the passed element and its children are focusable.
         *
         * We also make sure to exclude the elements that are passed. When we get
         * to document.body we know that we have reached the top of the DOM tree
         * and we can stop the iteration.
         */
        while (currentElement !== document.body) {
            (
                Array.from(
                    currentElement?.parentElement?.children ?? []
                ) as HTMLElement[]
            )
                .filter(
                    (sibling) =>
                        sibling !== currentElement &&
                        !sibling.inert &&
                        !sibling.hasAttribute(FOCUSED_ELEMENT_DATA_ATTRIBUTE) &&
                        !excludedElements.some((excludedElement) =>
                            excludedElement?.contains(sibling)
                        )
                )
                .forEach((element) => {
                    element.inert = true;

                    /**
                     * Keep track of the elements that have been made inert in this
                     * particular instance.
                     */
                    element.setAttribute(
                        INERT_ELEMENT_DATA_ATTRIBUTE,
                        this.#id
                    );
                });

            currentElement = currentElement?.parentElement ?? null;
        }
    }

    public enable() {
        this.#element.setAttribute(FOCUSED_ELEMENT_DATA_ATTRIBUTE, this.#id);
        this.#makeOutsideElementsInert();

        /**
         * Make all iframes inside the element non-interactive.
         */
        this.#getInertElements().forEach((element) => {
            const iframes = element.querySelectorAll('iframe');

            iframes.forEach((iframe: HTMLIFrameElement) =>
                this.#makeIframeNonInteractive(iframe)
            );
        });

        return this;
    }

    public disable() {
        this.#element.removeAttribute(FOCUSED_ELEMENT_DATA_ATTRIBUTE);
        /**
         * Remove the inert attribute from all elements that were made inert
         * by this instance.
         */
        this.#getInertElements().forEach((element) => {
            /**
             * Make all iframes inside the element interactive again.
             */
            const iframes = element.querySelectorAll('iframe');

            iframes.forEach((iframe: HTMLIFrameElement) =>
                this.#makeIframeInteractive(iframe)
            );

            element.removeAttribute(INERT_ELEMENT_DATA_ATTRIBUTE);
            element.inert = false;
        });

        return this;
    }

    public isTrapped() {
        return this.#element.hasAttribute(FOCUSED_ELEMENT_DATA_ATTRIBUTE);
    }
}
