/* eslint-disable react/jsx-props-no-spreading */
// @ts-strict-ignore
import React, { forwardRef } from 'react';
import classnames from 'classnames';
import alignItemsCss from '../alignItems.module.scss';
import justifyContentCss from '../justifyContent.module.scss';
import { type JustifyContentType, type AlignItemsType } from '../types';
import css from './stack.module.scss';

export type StackProps = {
    justifyContent?: JustifyContentType;
    alignItems?: AlignItemsType;
    className?: string;
    children?: React.ReactNode;
} & React.ComponentProps<'div'>;

export const Stack = forwardRef<HTMLDivElement, StackProps>(
    (
        {
            justifyContent = 'flexStart',
            alignItems = 'center',
            className = '',
            children,
            ...restProps
        },
        ref
    ) => (
        <div
            ref={ref}
            className={classnames(css.flex, className, {
                [alignItemsCss[alignItems as string]]: Boolean(alignItems),
                [justifyContentCss[justifyContent as string]]:
                    Boolean(justifyContent)
            })}
            {...restProps}
        >
            {children}
        </div>
    )
);
