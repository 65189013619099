import React from 'react';
import classnames from 'classnames';
import {
    DeprecatedLinkButton,
    type LinkButtonProps
} from 'bb/common/DeprecatedLinkButton';
import css from './sideMenu.module.scss';
import { SideMenuList } from './SideMenuList';

export type SideMenuItemProps = {
    /**
     * The label of the menu item.
     */
    label: string;
    /**
     * The props to pass to the link button.
     */
    linkProps: Omit<LinkButtonProps, 'ref'>;
    /**
     * The children of the menu item.
     *
     * If children are provided they will be rendered
     * as a nested list. This component handles both
     * rendering of the list item and its children through
     * recursion.
     */
    children?: SideMenuItemProps[];
} & Omit<React.ComponentProps<'li'>, 'children'>;

export const SideMenuItem = (props: SideMenuItemProps) => {
    const { label, linkProps, children, className, ...restProps } = props;

    return (
        <li
            {...restProps}
            className={classnames(css.sideMenuListItem, className)}
        >
            <DeprecatedLinkButton {...linkProps} variant="text" inline>
                {label}
            </DeprecatedLinkButton>
            {children && children.length > 0 && (
                <SideMenuList isNested>
                    {children.map((child) => (
                        <SideMenuItem key={child.label} {...child} />
                    ))}
                </SideMenuList>
            )}
        </li>
    );
};
