import React, { type CSSProperties } from 'react';
import ImportedSkeleton, {
    type SkeletonProps as ImportedSkeletonProps
} from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const SKELETON_DARK_COLOR_SCHEME = {
    '--base-color': 'var(--color-gray-06)',
    '--highlight-color': 'var(--color-gray-05)'
} as CSSProperties;

export type SkeletonProps = {
    /**
     * @defaultValue `'light'`
     */
    colorScheme?: 'light' | 'dark';
} & ImportedSkeletonProps;

export const Skeleton = (props: SkeletonProps) => {
    const {
        /**
         * We don't typically have borderRadius on our skeletons so we default it to 0.
         */
        borderRadius = 0,
        style,
        colorScheme = 'light',
        ...restProps
    } = props;

    return (
        <ImportedSkeleton
            {...restProps}
            style={
                {
                    ...(colorScheme === 'dark'
                        ? SKELETON_DARK_COLOR_SCHEME
                        : {}),
                    ...style
                } as CSSProperties
            }
            borderRadius={borderRadius}
        />
    );
};
