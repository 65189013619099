import React, { useMemo } from 'react';
import {
    RemappedRoutesProvider,
    type RemappedRoutesProviderProps
} from 'bb/app/router/RemappedRoutesContext';

export type AppRemappedRoutesProviderProps = Pick<
    RemappedRoutesProviderProps,
    'children'
>;

export const AppRemappedRoutesProvider = ({
    children
}: AppRemappedRoutesProviderProps) => {
    const value = useMemo(() => {
        const remapped: RemappedRoutesProviderProps['value'] = {};

        return remapped;
    }, []);

    return (
        <RemappedRoutesProvider value={value}>
            {children}
        </RemappedRoutesProvider>
    );
};
