import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type LocalStorageItems, type SessionStorageItems } from './types';
import {
    type StorageType,
    getStorageItem,
    setStorageItem,
    removeStorageItem
} from './utils';

export const useStorage = <
    TStorageType extends StorageType,
    TStorage extends
        | LocalStorageItems
        | SessionStorageItems = TStorageType extends 'localStorage'
        ? LocalStorageItems
        : SessionStorageItems,
    TStorageKey extends keyof TStorage = keyof TStorage
>(
    storageType: TStorageType,
    key: Extract<TStorageKey, string>
) => {
    const trackState = useRef(false);
    const [value, setValue] = useState<TStorage[TStorageKey] | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const listener = (e: StorageEvent) =>
            e.key === key &&
            trackState.current &&
            setValue(getStorageItem(storageType, key));

        window.addEventListener('storage', listener);

        if (trackState.current) {
            setValue(getStorageItem(storageType, key));
            setIsLoaded(true);
        }

        return () => window.removeEventListener('storage', listener);
    }, [key, trackState, storageType]);

    return {
        // keep track of reading value so we only set the state if necessary
        get value() {
            trackState.current = true;
            return value;
        },
        setValue: useMemo(
            () =>
                setStorageItem<TStorageType, TStorage, TStorageKey>(
                    storageType,
                    key
                ),
            [key, storageType]
        ),
        removeItem: useCallback(
            () =>
                removeStorageItem<TStorageType, TStorage, TStorageKey>(
                    storageType,
                    key
                ),
            [key, storageType]
        ),
        isLoaded
    };
};
