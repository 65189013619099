import React from 'react';
import dynamic from 'next/dynamic';

const LazyChristmasLogo = dynamic(() => import('./ChristmasLogo'), {
    ssr: true
});

export type LogoProps = {
    variant: 'white' | 'black';
} & React.ComponentProps<'img'>;

const isDecember = () => {
    const today = new Date();
    const month = today.getMonth();

    return month === 11; // December is month 11 (0-indexed)
};

const Logo: React.FC<LogoProps> = (props) => {
    const { alt = 'Bookbeat logo', variant, ...restProps } = props;

    if (isDecember()) {
        return <LazyChristmasLogo {...props} />;
    }

    const logoSrc =
        variant === 'white'
            ? '/images/svg/BookBeat_white.svg'
            : '/images/svg/BookBeat_black.svg';

    // eslint-disable-next-line @next/next/no-img-element
    return <img src={logoSrc} alt={alt} {...restProps} />;
};

export default Logo;
