import React from 'react';
import { useRouter } from 'bb/app/router';
import Logo from 'bb/common/Logo';
import { useTranslation } from 'bb/i18n';
import { Box, Flex } from 'bb/ui';
import { Link } from '../Link';
import { NAV_BAR_ID } from './consts';
import css from './navbar.module.scss';

export const SlimNavbar = () => {
    const { t } = useTranslation(['common', 'nav']);
    const { routes } = useRouter();

    return (
        <Box id={NAV_BAR_ID} bgColor="primary-white" className={css.slim}>
            <Flex
                justifyContent={'center'}
                alignItems="center"
                className={css.container}
            >
                <Link
                    route={routes.start}
                    title={t('common:startPageLinkLabel')}
                >
                    <span className={css.slimLogo}>
                        <Logo variant="black" />
                    </span>
                </Link>
            </Flex>
        </Box>
    );
};
