// @ts-strict-ignore
import { setCookieValue, getCookieValue } from 'bb/cookies/utils';
import { isBrowser } from 'bb/utils/environment';

let id;

const storeId = (traceId) => {
    if (isBrowser()) {
        setCookieValue({ name: 'bb-trace-id', value: traceId });
    } else {
        id = `local-${traceId}`;
    }
};

const getStoredId = () => {
    if (isBrowser()) {
        return getCookieValue('bb-trace-id');
    }
    return id;
};

export const getTraceId = () => {
    const traceId = getStoredId();
    if (traceId) {
        return traceId;
    }
    const newId = crypto.randomUUID();
    storeId(newId);
    return newId;
};
