import { useCallback, useMemo } from 'react';
import { DEFAULT_TOAST_POSITION, type ToastProps } from 'bb/ui/Toast';
import { createStore } from './simpleStore';

export type ToastStoreItem = Omit<ToastProps, 'id'> & {
    id: string;
};

export const toastStore = createStore<ToastStoreItem[]>([]);

export const makeToastItem = ({
    position = DEFAULT_TOAST_POSITION,
    ...item
}: ToastProps): ToastStoreItem => ({
    ...item,
    position,
    id: crypto.randomUUID()
});

export const useToastStore = () => {
    const [state] = toastStore.useStore();

    /**
     * Adds and returns item.
     */
    const add = useCallback((item: ToastProps): ToastStoreItem => {
        const toast = makeToastItem(item);
        toastStore.set((oldState) => [...oldState, toast]);

        return toast;
    }, []);

    /**
     * Removes the item and returns the the items.
     */
    const remove = useCallback(
        (id: string) =>
            toastStore.set((oldState) =>
                oldState.filter((item) => item.id !== id)
            ),
        []
    );

    return useMemo(
        () => ({
            add,
            remove,
            state
        }),
        [add, remove, state]
    );
};
