import React from 'react';
import classnames from 'classnames';
import { TextButton, type TextButtonProps } from 'bb/ui/Button';
import css from './skipButton.module.scss';

export const SKIP_BUTTON_CLASSNAME = 'skipButton';

export type SkipButtonProps = TextButtonProps;

export const SkipButton = ({ className, ...restProps }: SkipButtonProps) => (
    <TextButton
        {...restProps}
        className={classnames(css.root, className, SKIP_BUTTON_CLASSNAME)}
    />
);
