import React from 'react';
import { type GetLayout } from 'bb/page/types';
import { NavigateLoggedOutUser } from '../components/NavigateLoggedOutUser';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export const withNavigateLoggedOutUser = <TProps extends {}>(
    PassedComponent: React.ComponentType<TProps> & GetLayout<TProps>
) => {
    const WithNavigateLoggedOutUser = (props: TProps) => (
        <>
            <NavigateLoggedOutUser />
            <PassedComponent {...props} />
        </>
    );

    if (PassedComponent.getLayout) {
        WithNavigateLoggedOutUser.getLayout = PassedComponent.getLayout;
    }

    return WithNavigateLoggedOutUser;
};
