// @ts-strict-ignore
import personnummer from 'personnummer';
import { type ErrorResponse } from 'bb/api/browser/types';
import {
    type PendingCampaignResponse,
    PriceRuleTypes,
    type Campaign,
    type CampaignFormValues,
    type CampaignPayload,
    EligibilityRule,
    type CampaignResponse
} from 'bb/campaigns/types';
import { type ImageData } from 'bb/common/images/types';
import { getPublicEnv } from 'bb/config/env';
import { match } from 'bb/utils/match';

const LOCALE_TO_SHEER_ID_LOCALE = {
    en: 'en',
    sv: 'sv',
    de: 'de',
    'de-AT': 'de',
    'de-CH': 'de',
    fi: 'fi',
    pl: 'pl',
    da: 'da',
    nb: 'no',
    'nl-NL': 'nl',
    'nl-BE': 'nl'
};

export const isFixedOrRelative = (type) =>
    type === PriceRuleTypes.fixed || type === PriceRuleTypes.relative;

export const isPercentage = (type) => type === PriceRuleTypes.percent;

type LifeTimeProps = {
    days?: number;
    months?: number;
};

export const isWeeks = ({ days }): boolean => days % 7 === 0;
export const isMonths = ({ days }): boolean => days % 30 === 0;
export const isLifetime = ({ days, months }: LifeTimeProps): boolean =>
    days > 30 * 12 * 5 || months > 12 * 5;

export const campaignPeriodTextKey = (days?: number) =>
    match({ days })
        .on(isLifetime, () => 'campaign:lifetimeDiscount')
        .on(isMonths, () => 'campaign:nrOfMonthsWithDiscount')
        .on(isWeeks, () => 'campaign:nrOfWeeksWithDiscount')
        .otherwise(() => '');

export const campaignDaysPeriodTextKey = (days?: number) =>
    match({ days })
        .on(isLifetime, () => 'campaign:lifetimeDiscount')
        .otherwise(() => 'campaign:nrOfDaysWithDiscount');

export const formatSocialSecurityNumber = ({
    value
}: {
    value: string;
}): string => {
    try {
        return personnummer.parse(value).format(false).replace('+', '-');
    } catch {
        return value;
    }
};

export const validSocialSecurityNumber = (
    value: string | undefined,
    message?: string
) => personnummer.valid(value) || message;

export const requiresSocialSecurityNumber = (
    campaign?: Campaign | CampaignResponse
): boolean =>
    campaign?.eligibilityRules?.includes(
        EligibilityRule.personalIdentificationNumber
    );

export const requiresSheerIdVerification = (
    campaign?: Campaign | CampaignResponse
): boolean =>
    campaign?.eligibilityRules?.includes(EligibilityRule.sheerIdVerification);

export const requiresNordicChoiceMembership = (
    campaign?: Campaign | CampaignResponse
): boolean =>
    campaign?.eligibilityRules?.includes(EligibilityRule.nordicchoice);

export const formatCampaignPayload = (
    formValues?: CampaignFormValues
): CampaignPayload => {
    const { campaignCode, ...additionalData } = formValues || {};

    return {
        /**
         * If the campaignCode equals 'default' we should send an empty string.
         * This is because it's not an actual campaign, but more of a placeholder.
         */
        campaignCode: campaignCode === 'default' ? '' : campaignCode,
        additionalData
    };
};

const rulesThatNeedAdditionalData = {
    personalidentificationnumber: [
        EligibilityRule.personalIdentificationNumber
    ],
    sheeridverification: [EligibilityRule.sheerIdVerification],
    nordicchoice: [
        EligibilityRule.nordicchoiceMemberId,
        EligibilityRule.nordicchoiceEmail
    ]
};

export const isWaitingForCampaignAdditionalData = (
    campaign?: Campaign,
    campaignData?: CampaignFormValues
): boolean => {
    if (!campaign) {
        return false;
    }
    const { eligibilityRules, terms } = campaign;
    const foundRule = eligibilityRules?.find((rule) => {
        const fields = rulesThatNeedAdditionalData[rule];

        return fields?.some(
            (field) => !Object.keys(campaignData || {}).includes(field)
        );
    });

    if (foundRule) return true;

    const foundTerm = terms?.find(
        (term) => !Object.keys(campaignData || {}).includes(term.id)
    );
    return Boolean(foundTerm);
};

export const sheerIdLink = (
    campaign?: Campaign,
    market?: string,
    locale?: string
) => {
    const sheerIdLocale = LOCALE_TO_SHEER_ID_LOCALE[locale];

    return `${getPublicEnv(
        'NEXT_PUBLIC_SHEER_ID_URL'
    )}?customerID=student&market=${market}&campaign=${campaign?.code}&locale=${sheerIdLocale}`;
};

export const fallbackImage: ImageData = {
    src: 'https://images.ctfassets.net/4s7izhcdroyy/gB4zdOKHTl9yj2IqFpoaF/98cc6cd50c6dfd660d70c333b7af506f/bb_emilia.png',
    altText: 'Woman doing laundry',
    title: 'LaundryLady'
};

export const hasCampaignErrors = ({
    registrationCampaignCode,
    campaign,
    error
}: {
    registrationCampaignCode?: string;
    campaign?: Campaign;
    error: ErrorResponse | null;
}) => {
    if (!registrationCampaignCode) {
        return false;
    }
    if (campaign) {
        if (campaign.code === registrationCampaignCode && error === null) {
            return false;
        }
    }
    return true;
};

export const isDefaultPendingCampaign = (
    pendingCampaign?: PendingCampaignResponse
) => pendingCampaign?.campaignCode === 'default';
